import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import woodGrain from '../images/wood-grain-textures-1280x720.jpg';
import './header.css';
import Menu from "./Menu";

const Header = ({ siteTitle }) => (
  <header
    style={{
      backgroundImage: `url(${woodGrain})`,
      backgroundSize: 'cover',
    }}
  >
    <div
      className='header'
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `0 1rem`,
      }}
    >
      <h1 className="header-short header-title">SIMTRIM</h1>
      <div className='header-content'>
        <h1 className='header-title'>
          <Link
            to="/"
            style={{
              color: `white`,
              textDecoration: `none`,
            }}
          >
            {siteTitle}
          </Link>
        </h1>
        <h2 className='header-subtitle'>Removable and Re-installable Molding and Trim</h2>
      </div>
      <Menu />
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
