import React, { useState } from 'react';
import { Link } from 'gatsby';

export default function Menu() {

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  return (
    <>
      <button
        onClick={toggleMenu}
        onKeyDown={e => {
          if (e.key === 'Enter') toggleMenu();
        }}
        className='menu-btn'
      >
        {!menuOpen && <span id='menu-btn'>Menu</span>}
        {menuOpen && <span id='close-btn'>Close</span>}
      </button>
      <div className={`menu ${!menuOpen ? "menu-closed" : ""}`}>
        <ul className="nav-links">
          <li><Link to="/" className="nav-link">What is SIMTRIM</Link></li>
          <li><Link to="/reasons" className="nav-link">Reasons for using SIMTRIM</Link></li>
          <li><Link to="/history" className="nav-link">History of SIMTRIM</Link></li>
          <li><Link to="/advantages" className="nav-link">Advantages of SIMTRIM</Link></li>
          <li><Link to="/traditional-disadvantages" className="nav-link">Disadvantages of traditional methods</Link></li>
          <li><Link to="/what-is-required" className="nav-link">What is required</Link></li>
        </ul>
      </div>
    </>
  )
}
